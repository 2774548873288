@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
}

p {
  font-family: "Actor", sans-serif;
  line-height: 1.75rem;
  color: #666666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-underline {
  text-underline-offset: 10px;
  text-decoration-thickness: 2px;
}

.gallery {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px; /* Adjust the gap as needed */
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.image-container img {
  max-width: 100%;
  height: auto;
  display: block;
}

.text-container {
  flex: 1;
}

/*
specific to donate page ...
*/
.donate p {
  color: white;
  font-weight: 100;
}

.donate h2 {
  color: #a7d08b;
  font-weight: 700;
}
